<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Dashboard</div>
    <div class="col-lg-6 col-12 text-end d-flex justify-content-end">
      <div class="col-3" v-if="this.basepathstatus != 5">
        <Multiselect v-model="sortfeedback" :options="sortfeedbacklist" :searchable="false" label="label"
          placeholder="Select" class="custom-primary-outline-dropdown" :canClear="false" :closeOnSelect="true"
          :object="true" noOptionsText="No Result" :canDeselect="false" />
      </div>
    </div>
  </div>
  <div class="comman-overflow-scroll-outer" style="height: calc(100vh - 141px);">
    <div class="row g-3" v-if="this.basepathstatus != 5">
      <div class="col-lg-3 col-md-3 col-12">
        <div class="feedback-small-box-outer">
          <div class="box-icon">
            <div class="box-icon-inner d-flex justify-content-center">
              <img src="/assets/images/icon/feedback-rating.svg" alt="" width="24" />
            </div>
          </div>
          <div class="box-label">Overall Rating</div>
          <div class="box-points-outer d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="rating-value">4.5</div>
              <div class="rating-graph-up">
                <i class="fas fa-arrow-up me-1"></i>20%
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="rating-mtd">MTD</div>
              <div class="rating-points">4.4</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="feedback-small-box-outer">
          <div class="box-icon">
            <div class="box-icon-inner d-flex justify-content-center">
              <img src="/assets/images/icon/feedback-collected.svg" alt="" width="24" />
            </div>
          </div>
          <div class="box-label"><span v-if="this.basepathstatus == 4"></span><span v-else-if="this.basepathstatus == 3">Citizen</span><span v-else>Voters</span> By Area
          </div>
          <div class="box-points-outer d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="rating-value">4.5</div>
              <div class="rating-graph-down">
                <i class="fas fa-arrow-down me-1"></i>20%
              </div>
            </div>
            <div class="d-flex align-items-center">
              <div class="rating-mtd">MTD</div>
              <div class="rating-points">3.5K</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="feedback-small-box-outer">
          <div class="box-icon">
            <div class="box-icon-inner d-flex justify-content-center">
              <img src="/assets/images/icon/feedback-pending.svg" alt="" width="22" />
            </div>
          </div>
          <div class="box-label">Pending Feedback</div>
          <div class="box-points-outer d-flex align-items-center">
            <div class="rating-value">1.5K</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-12">
        <div class="feedback-small-box-outer">
          <div class="box-icon">
            <div class="box-icon-inner d-flex justify-content-center">
              <img src="/assets/images/icon/feedback-day.svg" alt="" width="20" />
            </div>
          </div>
          <div class="box-label">Feedback Per Day</div>
          <div class="box-points-outer d-flex align-items-center">
            <div class="rating-value">50</div>
            <div class="rating-graph-down">
              <i class="fas fa-arrow-down me-1"></i>20%
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="feedback-chart-outer pb-3">
          <div class="chart-label">Rating From <span v-if="this.basepathstatus == 4"></span><span v-else-if="this.basepathstatus == 3">Citizen</span><span
              v-else>Voters</span></div>
          <div>
            <Chart type="doughnut" :data="citizenChartData" :options="citizenLightOptions" responsive="true" />
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <div class="row gy-3">

          <div class="col-lg-12 col-md-12 col-12">
            <div class="feedback-chart-outer">
              <div class="chart-label"><span v-if="this.basepathstatus == 4"></span><span v-else-if="this.basepathstatus == 3">Citizen</span><span v-else>Voters</span> By
                Area</div>
              <div>
                <Chart type="bar" class="p-2" :data="voterAreaData" :options="voterAreaOptions" responsive="true" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12" v-if="this.basepathstatus == 3">
            <div class="feedback-chart-outer">
              <div class="d-flex justify-content-between">
                <div class="chart-label">Recent Polls</div>
              </div>
              <table class="
                  table
                  custom-overview-table-outer
                  align-middle
                  table-responsive
                ">
                <thead>
                  <tr>
                    <td scope="col" style="width: 70%"></td>
                    <td scope="col" style="width: 15%" class="text-end">
                      %
                    </td>
                    <td scope="col" style="width: 15%" class="text-end">
                      %
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 70%">Have you taken benefit of 50% Off ST Scheme?</td>
                    <td style="width: 15%" class="text-end">
                      <span class="me-3">Yes<span class="text-success ms-2">92</span></span>
                    </td>
                      <td style="width: 15%" class="text-end">
                        <span class="me-1">|</span>
                      <span class="me-3">No<span class="text-danger ms-2">8</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 70%">How is Goverment work?</td>
                    <td style="width: 15%" class="text-end">
                      <span class="me-3">Good<span class="text-success ms-2">72</span></span>
                    </td>
                      <td style="width: 15%" class="text-end">
                        <span class="me-1">|</span>
                      <span class="me-3">Ok<span class="text-danger ms-2">28</span></span>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 70%">Do you get weather informations from Goverment?</td>
                    <td style="width: 15%" class="text-end">
                      <span class="me-3">Yes<span class="text-success ms-2">98</span></span>
                    </td>
                      <td style="width: 15%" class="text-end">
                        <span class="me-1">|</span>
                      <span class="me-3">No<span class="text-danger ms-2">2</span></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12" v-else>
            <div class="feedback-chart-outer">
              <div class="d-flex justify-content-between">
                <div class="chart-label">Ratings as per work</div>
                <div class="content-header-btn-group" :style="{ padding: '7px 16px' }">
                  <div class="btn-group custom-group-radio-btn header-btn" role="group"
                    aria-label="Basic radio toggle button group">
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" checked />
                    <label class="btn btn-outline-primary" for="btnradio1">Departments</label>
                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" />
                    <label class="btn btn-outline-primary" for="btnradio2">Offices</label>
                  </div>
                </div>
              </div>
              <table class="
                  table
                  custom-overview-table-outer
                  align-middle
                  table-responsive
                ">
                <thead>
                  <tr>
                    <td scope="col" style="width: 80%">Name</td>
                    <td scope="col" style="width: 20%" class="text-end">
                      Rating
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style="width: 80%">Corporation</td>
                    <td style="width: 15%" class="text-end">
                      4.5<i class="fas fa-star text-success ms-1 align-baseline" :style="{ fontSize: '9px' }"></i>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 80%">Electricity</td>
                    <td style="width: 15%" class="text-end">
                      4.5<i class="fas fa-star text-success ms-1 align-baseline" :style="{ fontSize: '9px' }"></i>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 80%">Water</td>
                    <td style="width: 15%" class="text-end">
                      4.5<i class="fas fa-star text-success ms-1 align-baseline" :style="{ fontSize: '9px' }"></i>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 80%">Road</td>
                    <td style="width: 15%" class="text-end">
                      4.5<i class="fas fa-star text-success ms-1 align-baseline" :style="{ fontSize: '9px' }"></i>
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 80%">Cleaness</td>
                    <td style="width: 15%" class="text-end">
                      4.5<i class="fas fa-star text-success ms-1 align-baseline" :style="{ fontSize: '9px' }"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      basepathstatus: 0,
      sortfeedback: { label: "This Month", value: "1" },
      sortfeedbacklist: [
        { label: "This Month", value: "1" },
        { label: "Next 3 Months", value: "2" },
        { label: "This Year", value: "3" },
      ],
      voterAreaData: {
        labels: [
          "411045",
          "411046",
          "411047",
          "411048",
          "411049",
          "411050",
          "411051",
        ],
        datasets: [
          {
            label: "Pincode",
            backgroundColor: "#42A5F5",
            data: [40000, 47000, 52000, 43000, 44000, 2000, 34000],
          },
        ],
      },
      voterAreaOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
          y: {
            ticks: {
              color: "#495057",
            },
            grid: {
              color: "#ebedef",
            },
          },
        },
      },
      citizenChartData: {
        labels: ["1 Star", "2 Star", "3 Star", "4 Star", "5 Star"],
        datasets: [
          {
            data: [44, 55, 41, 17, 15],
            backgroundColor: [
              "#FF9FB2",
              "#FEDA87",
              "#80D39B",
              "#7C90DB",
              "#263C75",
            ],
            hoverBackgroundColor: [
              "#FF9FB2",
              "#FEDA87",
              "#80D39B",
              "#7C90DB",
              "#263C75",
            ],
          },
        ],
      },
      citizenLightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
      baseUrl :window.location.origin,
    };
  },
  mounted() {
      if (this.baseUrl == 'https://vc.nextedgecivictech.in') {
        this.basepathstatus = 2;
      } else if (this.baseUrl == 'https://demo.cm-connect.in') {
        this.basepathstatus = 3;
      } else if (this.baseUrl == 'https://test.nextedgecivictech.in'){
        this.basepathstatus = 4;
      } else if (this.baseUrl == 'https://vision.nextedgecivictech.in'){
        this.basepathstatus = 5;
      } else {
        this.basepathstatus = 1;
      }
    },
};
</script>
<style>
.feedback-small-box-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
  padding: 16px 20px;
}

.feedback-small-box-outer .box-icon .box-icon-inner {
  background: #f2f4fb 0% 0% no-repeat padding-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
}

.feedback-small-box-outer .box-label {
  letter-spacing: 0.26px;
  color: #4a5463;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  line-height: 13px;
  padding-top: 16px;
}

.feedback-small-box-outer .box-points-outer {
  padding-top: 10px;
}

.feedback-small-box-outer .box-points-outer .rating-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 25px;
  letter-spacing: 0.5px;
  color: #2b2e38;
  text-align: left;
  line-height: 25px;
}

.feedback-small-box-outer .box-points-outer .rating-graph-up {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.26px;
  color: #479d21;
  text-align: left;
  padding-left: 20px;
}

.feedback-small-box-outer .box-points-outer .rating-graph-down {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  line-height: 13px;
  letter-spacing: 0.26px;
  color: #eb5d5d;
  text-align: left;
  padding-left: 20px;
}

.feedback-small-box-outer .box-points-outer .rating-mtd {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
  padding-left: 20px;
}

.feedback-small-box-outer .box-points-outer .rating-points {
  font-family: "AcuminPro-SemiBold";
  font-size: 19px;
  line-height: 19px;
  letter-spacing: 0.38px;
  color: #2b2e38;
  padding-left: 8px;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}

/* custom table css start here  */
.custom-overview-table-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.custom-overview-table-outer thead {
  background: #f2f4fb 0% 0% no-repeat padding-box;
}

.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  border-bottom-width: 0px;
}

.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}

.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  letter-spacing: 0.26px;
  color: #4a5463;
  line-height: 13px;
}

.custom-overview-table-outer thead tr td,
.custom-overview-table-outer thead tr th {
  padding: 10px 16px;
}

.custom-overview-table-outer tbody tr td,
.custom-overview-table-outer tbody tr th {
  padding: 16px;
}

.custom-overview-table-outer tbody tr td .table-heading {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}

.custom-overview-table-outer tbody tr td .table-heading,
.custom-overview-table-outer tbody tr td .table-subheading {
  padding-bottom: 8px;
}

.custom-view-detail-btn {
  color: #5266e5;
  border-color: #5266e5;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #5266e5;
  border-radius: 4px;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0.26px;
}

.custom-view-detail-btn:hover {
  color: #5266e5;
}

.custom-view-detail-btn:focus-visible {
  outline: 0;
}

table.custom-overview-table-outer {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.custom-overview-table-outer thead {
  flex: 0 0 auto;
}

.custom-overview-table-outer tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 194px;
}

.custom-overview-table-outer tr {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.custom-overview-table-outer tbody::-webkit-scrollbar {
  display: block;
}

.custom-overview-table-outer tbody::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.custom-overview-table-outer tbody::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-spinner-loader-btn {
  width: 15px;
  height: 15px;
  border: 2px solid #ced9e9;
  border-right-color: transparent;
}

/* custom table css end here  */
</style>